<template>
    <DataTable paginator :value="state.users" :rowsPerPageOptions="[5, 10, 20]" lazy :loading="state.loading.users"
        :currentPage="state.currentPage" :rows="state.perPage" ref="dt" dataKey="id" :totalRecords="state.totalRecords"
        :autoLayout="true" @page="onPage($event)">
        <Column field="id" header="ID">
            <template #body="slotProps">
                {{ slotProps.data.id }}
            </template>
        </Column>
        <Column field="name" header="Usuário">
            <template #body="slotProps">
                {{ slotProps.data.name }}
            </template>
        </Column>
        <Column field="login" header="Login">
            <template #body="slotProps">
                {{ slotProps.data.login }}
            </template>
        </Column>
        <Column field="status" header="Status">
            <template #body="slotProps">
                <Badge class="mt-2 mb-2" style="font-size: 1rem"
                    :severity="`${changeStatusBoleanToColor(slotProps.data.active)}`"
                    :value="changeStatusBoleanToText(slotProps.data.active)" />
            </template>
        </Column>
        <Column style="width: 20%">
            <template #header>
                Ações
            </template>
            <template #body="slotProps">
                <div class="container-button">
                    <Button type="button" icon="pi pi-pencil" @click="openDetailsModal(slotProps.data)"
                        v-tooltip.bottom="{ value: 'Editar', showDelay: 1000, hideDelay: 300 }" />
                </div>
            </template>
        </Column>
    </DataTable>

    <Dialog v-model:visible="state.detailsModal" modal :header="state.user.name">
        <div class="col-12 mb-2 text-center">
            <div class="ml-1 my-3">
                <div class="text-500">Nome:</div>
                <Inplace :closable="true">
                    <template #closeicon>
                        <i class="pi pi-check"></i>
                    </template>
                    <template #display>
                        {{ state.modifiedUser.name || 'Vazio' }}
                    </template>
                    <template #content>
                        <input class="p-inputtext" v-model="state.modifiedUser.name" autofocus />
                    </template>
                </Inplace>
            </div>

            <div class="ml-1 my-3">
                <div class="text-500">Login:</div>
                <Inplace :closable="true">
                    <template #closeicon>
                        <i class="pi pi-check"></i>
                    </template>
                    <template #display>
                        {{ state.modifiedUser.login || 'Vazio' }}
                    </template>
                    <template #content>
                        <input class="p-inputtext" v-model="state.modifiedUser.login" autofocus />
                    </template>
                </Inplace>
            </div>

            <div class="ml-1 my-3">
                <div class="text-500">Status:</div>
                <Inplace :closable="true">
                    <template #closeicon>
                        <i class="pi pi-check"></i>
                    </template>
                    <template #display>
                        {{ changeStatusBoleanToText(state.modifiedUser.active) || 'Vazio' }}
                    </template>
                    <template #content>
                        <Dropdown class="mt-1" v-model="state.modifiedUser.active" :options="['Ativado', 'Desativado']" />
                    </template>
                </Inplace>
            </div>

            <div class="ml-1 my-3">
                <div class="text-500">Senha:</div>
                <Inplace :closable="true">
                    <template #closeicon>
                        <i class="pi pi-check"></i>
                    </template>
                    <template #display>
                        {{ state.modifiedUser.password || 'Vazio' }}
                    </template>
                    <template #content>
                        <Password v-model="state.modifiedUser.password" :feedback="false" />
                    </template>
                </Inplace>
            </div>
        </div>
        <br>

        <div><button id="save-client" class="p-button" @click="$refs.checkChanges.toggle">Conferir Alterações</button></div>
        <OverlayPanel showCloseIcon ref="checkChanges">
            <div v-for="(item, index) in changedItems()" :key="index" class="text-center">
                <div>{{ state.userLabels[item.field] || item.field }}</div>
                <i v-if="item.field === 'password'" class="pi pi-eye-slash mt-2" />
                <div v-else class="text-center">
                    <div v-if="item.field === 'active'" class="text-center mt-2">{{ changeStatusBoleanToText(item.original)
                    }}
                    </div>
                    <span v-else>{{ item.original }}</span>
                </div>
                <div class="text-center"><i class="pi pi-arrow-circle-down text-green-400"></i></div>
                <i v-if="item.field === 'password'" class="pi pi-key mt-2" />
                <div v-else class="text-center">
                    <div v-if="item.modified === 'active'" class="text-center mt-2">{{
                        changeStatusBoleanToText(item.original) }}
                    </div>
                    <span>{{ item.modified }}</span>
                </div>
                <hr style="border-color: #fff3">
            </div>
            <div v-if="changedItems().length == 0">
                Nenhuma Alteração
            </div>
            <div v-else>
                <button :disabled="state.loading.save" class="p-button bg-green-400 hover:bg-green-600"
                    @click="saveChanges">Salvar Mudanças</button>
            </div>
        </OverlayPanel>
    </Dialog>
</template>

<script>

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import Inplace from 'primevue/inplace'
import OverlayPanel from 'primevue/overlaypanel'
import Password from 'primevue/password'
import Badge from 'primevue/badge'
import Dropdown from 'primevue/dropdown'

import services from '../../services'
import { reactive } from 'vue'

import useNotificationToast from '../../composables/useNotificationToast'
import { useStore } from 'vuex'


export default {

    components: { DataTable, Column, Button, Dialog, Inplace, OverlayPanel, Password, Badge, Dropdown },

    setup() {
        const state = reactive({
            userFilter: {},

            users: [],
            user: {},
            modifiedUser: {},

            loading: {
                save: false,
                users: false,
            },

            currentPage: 1,
            perPage: 5,
            totalRecords: 0,

            detailsModal: false,
            checkChangesModal: false,

            userLabels: {
                name: 'Nome',
                login: 'Login',
                password: 'Senha',
                active: 'Status',
            }

        })

        const { success, error } = useNotificationToast()
        const store = useStore()

        loadUsers()

        function loadUsers() {

            state.loading.users = true

            const params = {
                userFilter: state.userFilter,
                page: state.currentPage,
                perPage: state.perPage,
            }

            const token = store.getters.getToken

            services.users.getAll({ params, token })
                .then((res) => {
                    state.users = res.data.data
                    state.currentPage = res.data.meta.current_page
                    state.totalRecords = res.data.meta.total
                })
                .catch(() => {
                    error("Não foi possível carregar os usuários.")
                })
                .finally(() => { state.loading.users = false })

        }

        function onPage(event) {
            state.currentPage = event.page + 1
            state.perPage = event.rows
            loadUsers()
        }

        function openDetailsModal(user) {
            state.user = user
            state.modifiedUser = { ...user }
            state.modifiedUser.password = null

            state.detailsModal = true
        }

        function changedItems() {
            const changed = []

            for (const key in state.user) {

                const original = state.user[key]
                const modified = state.modifiedUser[key]



                if (original != modified) {
                    changed.push({
                        original,
                        modified,
                        field: key
                    })
                }

            }

            if (state.modifiedUser.password != null) {
                changed.push({
                    field: 'password'
                })
            }

            return changed
        }

        function saveChanges() {

            state.loading.save = true

            const modifiedUser = state.modifiedUser

            modifiedUser.active = modifiedUser.active === 'Ativado' || modifiedUser.active === true ? true : false

            const token = store.getters.getToken



            services.users.update({ modifiedUser, token }).then(() => {
                success('Usuário atualizado com sucesso')
                state.detailsModal = false
                loadUsers()
            }).catch(() => {
                error("Não foi possível atualizar o usuário.")
            }).finally(() => {
                state.loading.save = false
            })

        }

        function changeStatusBoleanToColor(active) {
            return active ? 'success' : 'danger'
        }

        function changeStatusBoleanToText(active) {
            return active ? 'Ativado' : 'Desativado'
        }

        return {
            state,
            loadUsers,
            onPage,
            openDetailsModal,
            changedItems,
            saveChanges,
            changeStatusBoleanToColor,
            changeStatusBoleanToText
        }
    }
}
</script>